import { FillButton, FlexBox, Text } from '@codecademy/gamut';
import { Logo } from '@mono/brand';
import { getRegisterPath } from '@mono/util-url';
import React from 'react';

export const FooterCTA: React.FC = () => {
  return (
    <FlexBox
      alignItems="center"
      flexDirection="column"
      gap={32}
      my={80 as 0}
      px={24}
    >
      <Logo height={44} />
      <Text fontSize={26} fontWeight={400} maxWidth={700} textAlign="center">
        Join thousands of learners building real-world coding skills and
        advancing their careers. Sign up today to start learning, track your
        progress, and reach your goals faster.
      </Text>
      <FillButton href={getRegisterPath()} data-testid="get-started-link">
        Get started
      </FillButton>
    </FlexBox>
  );
};
