import {
  Box,
  ContentContainer,
  FlexBox,
  IconButton,
  Text,
  TextProps,
} from '@codecademy/gamut';
import { ArrowRightIcon } from '@codecademy/gamut-icons';
import { CheckerDense } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

interface CTABannerProps extends ComponentProps<typeof ContentContainer> {
  title: string;
  description: string;
  href: string;
  buttonTooltip: string;
  textColor: TextProps['color'];
}

const ColorIconButton = styled(IconButton)<{ color: TextProps['color'] }>`
  color: ${(props) => theme.colors[props.color as keyof typeof theme.colors]};
`;

const TransparentCheckerDense = styled(CheckerDense)`
  color: ${theme.colors['white-600']};
  opacity: 65%;
  pointer-events: none;
`;

export const CTABanner: React.FC<CTABannerProps> = ({
  title,
  description,
  href,
  buttonTooltip,
  textColor,
  ...props
}) => {
  return (
    <ContentContainer {...props}>
      <FlexBox
        border={1}
        borderColor="border-tertiary"
        borderRadius="lg"
        pr={40}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="stretch"
        overflow="clip"
        gap={24}
      >
        <FlexBox flexDirection="row">
          <TransparentCheckerDense
            maxWidth={16}
            height={{ _: '100%', md: 128 }}
            color="white-600"
          />
          <Box pl={24} py={24}>
            <Text
              as="h2"
              variant="title-md"
              fontWeight="normal"
              fontFamily="accent"
              color={textColor}
              pb={16}
            >
              {title}
            </Text>
            <Text fontSize={20} color="text">
              {description}
            </Text>
          </Box>
        </FlexBox>
        {/* TODO: add tracking */}
        <Box alignSelf="center">
          <ColorIconButton
            href={href}
            icon={ArrowRightIcon}
            size="large"
            tip={buttonTooltip}
            tipProps={{
              placement: 'floating',
              hideAriaToolTip: true,
            }}
            color={textColor}
            aria-label={buttonTooltip}
          />
        </Box>
      </FlexBox>
    </ContentContainer>
  );
};
