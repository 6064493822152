import { Box } from '@codecademy/gamut';
import { CurriculumCardData } from '@mono/data-curriculum-cards';
import { getWelcomeOnboarding } from '@mono/util-url';
import React from 'react';

import { CTABanner } from './components/CTABanner';
import { FooterCTA } from './components/FooterCTA';
import { GridRegularDivider } from './components/GridRegularDivider';
import { Hero } from './components/Hero';
import { LearnerStories } from './components/LearnerStories';
import { LearnersWorkAt } from './components/LearnersWorkAt';
import {
  LegacyHomepage,
  LegacyHomepageProps,
} from './components/LegacyHomepage';
import { SearchWithFeaturedContainers } from './components/SearchWithFeaturedContainers';
import { SkillsMenuSection } from './components/SkillsMenuSection';
import { ValueProps } from './components/ValueProps';

export interface HomepageProps extends LegacyHomepageProps {
  showRevampedHomepage: boolean;
  featuredContainers: CurriculumCardData[];
}

export const Homepage: React.FC<HomepageProps> = ({
  contentfulData,
  isUserInIndia,
  products,
  showSignUpCtaVariant,
  showRevampedHomepage,
  featuredContainers,
}) => {
  return (
    <Box as="main" data-testid="homepage-container" id="heading">
      {showRevampedHomepage ? (
        <>
          <Hero />
          <LearnersWorkAt />
          <CTABanner
            title="Develop your team's skills"
            description="Add team members, assign learning goals, and track their progress effortlessly."
            href="/business"
            buttonTooltip="Go to Teams product page"
            textColor="blue-300"
          />
          <GridRegularDivider pt={64} />
          <SearchWithFeaturedContainers
            featuredContainers={featuredContainers}
          />
          <SkillsMenuSection />
          <CTABanner
            title="Need help figuring out what to learn?"
            description="Take our quiz and get recommended learning recommendations."
            href={getWelcomeOnboarding()}
            buttonTooltip="Go to recommendation quiz"
            textColor="green-400"
            py={64}
          />
          <ValueProps />
          <GridRegularDivider pt={64} />
          <LearnerStories {...contentfulData.learnerStories} />
          <GridRegularDivider pb={64} />
          <FooterCTA />
        </>
      ) : (
        <LegacyHomepage
          contentfulData={contentfulData}
          isUserInIndia={isUserInIndia}
          products={products}
          showSignUpCtaVariant={showSignUpCtaVariant}
        />
      )}
    </Box>
  );
};
