import {
  Anchor,
  Box,
  Card,
  FlexBox,
  HeadingTags,
  Text,
} from '@codecademy/gamut';
import { LevelIcon } from '@codecademy/gamut-icons';
import { Background, theme, variant } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import {
  CourseDifficulty,
  getTimeToCompleteDisplayAmount,
  getTimeToCompleteDisplayDuration,
} from '@mono/brand';
import {
  CurriculumCardData,
  isCourseData,
  isExternalCertPathData,
  PathData,
  PathGoalEnum,
} from '@mono/data-curriculum-cards';
import { useTracking } from '@mono/data-use-tracking';
import { UserClickData } from '@mono/tracking';
import lowerFirst from 'lodash/lowerFirst';
import pluralize from 'pluralize';

enum ContainerType {
  COURSE = 'Course',
  SKILLPATH = 'Skill path',
  CAREERPATH = 'Career path',
  CERTIFICATIONPATH = 'Certification path',
}

const Label = styled(Box)(
  variant({
    base: {
      borderRadius: 'lg',
      px: 12,
      width: 'fit-content',
    },
    variants: {
      [ContainerType.COURSE]: {
        background: theme.colors['green-400'],
      },
      // TODO: get color for skill path cards
      [ContainerType.SKILLPATH]: {
        background: theme.colors['red-100'],
      },
      [ContainerType.CAREERPATH]: {
        background: '#FFE712',
      },
      [ContainerType.CERTIFICATIONPATH]: {
        background: '#67C4FF',
      },
    },
  })
);

interface SimpleCurriculumCardProps {
  content: CurriculumCardData;
  headingLevel: HeadingTags;
  trackingData: Omit<Partial<UserClickData>, 'slug'>;
}

export const SimpleCurriculumCard: React.FC<SimpleCurriculumCardProps> = ({
  content,
  headingLevel,
  trackingData,
}) => {
  const { trackUserClick } = useTracking();

  const { title, shortDescription, difficulty, timeToComplete, type } = content;

  // only courses can be free
  const isFree = isCourseData(content) && !content.pro;

  // external paths don't have lessons
  const numLessons = isExternalCertPathData(content) ? 0 : content.lessonCount;

  let containerType: ContainerType;
  let trackingTarget = '';
  switch (lowerFirst(type)) {
    case 'path':
      if ((content as PathData).goal === PathGoalEnum.Skill) {
        // don't know why checking for path first isn't enough here
        containerType = ContainerType.SKILLPATH;
        trackingTarget = 'skillpath_card';
      } else {
        containerType = ContainerType.CAREERPATH;
        trackingTarget = 'careerpath_card';
      }
      break;
    case 'externalCertPath':
      containerType = ContainerType.CERTIFICATIONPATH;
      trackingTarget = 'certificationpath_card';
      break;
    default:
      containerType = ContainerType.COURSE;
      trackingTarget = 'course_card';
  }

  const href = content.urlPath;

  const renderTimeToCompleteOrNumLessons = () => {
    if (typeof timeToComplete === 'number' && timeToComplete >= 0) {
      const displayAmount = getTimeToCompleteDisplayAmount(timeToComplete);
      const displayDuration = getTimeToCompleteDisplayDuration(timeToComplete);
      return (
        <Text
          variant="p-small"
          pl={4}
          whiteSpace="nowrap"
          aria-label={`${displayAmount} ${displayDuration}`}
        >
          {displayAmount} {displayDuration}
        </Text>
      );
    }
    if (numLessons > 0) {
      const label = pluralize('Lesson', numLessons);
      return (
        <Text
          variant="p-small"
          pl={4}
          whiteSpace="nowrap"
          aria-label={`${numLessons} ${label}`}
        >
          {numLessons} {label}
        </Text>
      );
    }

    return null;
  };

  return (
    <Anchor
      href={href}
      onClick={() => {
        trackUserClick({
          target: trackingTarget,
          href,
          ...trackingData,
          slug: content.slug,
        });
      }}
      variant="interface"
      height="100%"
      width="100%"
    >
      <Background bg="navy" height="100%" borderRadius="xl">
        <Card
          borderRadius="xl"
          isInteractive
          borderColor="border-tertiary"
          overflow="hidden"
          height="100%"
        >
          <FlexBox
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box>
              <Label variant={containerType}>
                <Text fontFamily="accent" fontSize={14} color="navy-900">
                  {`${
                    isFree && containerType === ContainerType.COURSE
                      ? 'Free course'
                      : containerType
                  }`}
                </Text>
              </Label>
              <Text as={headingLevel} variant="title-sm" pt={16} pb={8}>
                {title}
              </Text>
              <Text variant="p-small" truncate="ellipsis" truncateLines={4}>
                {shortDescription}
              </Text>
            </Box>
            <FlexBox
              justifyContent="space-between"
              flexWrap="wrap"
              fontSize={14}
              color="text-secondary"
            >
              {difficulty && (
                <FlexBox flexDirection="row" alignItems="center">
                  <LevelIcon size={16} />
                  <Text pl={8}>{`${difficulty}${
                    difficulty === CourseDifficulty.Beginner ? ' Friendly' : ''
                  }`}</Text>
                </FlexBox>
              )}
              {renderTimeToCompleteOrNumLessons()}
            </FlexBox>
          </FlexBox>
        </Card>
      </Background>
    </Anchor>
  );
};
