import {
  Box,
  ContentContainer,
  FlexBox,
  Text,
  TextButton,
} from '@codecademy/gamut';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

import asteriskBulletPoint from './assets/asterisk-bullet-point.svg';

const LineGridPatternContainer = styled(Box)`
  background-size: 68px 68px;
  background-image: linear-gradient(
      to right,
      ${theme.colors['white-200']} 1px,
      transparent 1px
    ),
    linear-gradient(
      to bottom,
      ${theme.colors['white-200']} 1px,
      transparent 1px
    );
  box-shadow: inset 0 0 0 1px ${theme.colors['white-200']};
  border-radius: 16px;
`;

const SlideIndicator = styled(Box)<{ isActiveSlide?: boolean }>`
  background-color: ${(props) =>
    props.isActiveSlide
      ? theme.colors.white
      : theme.colors['background-disabled']};
  border-radius: 100px;
  transition: background-color 0.2s ease-in;
`;

const Image = Box.withComponent('img');

const Slide = styled(FlexBox)<{
  percentage: string;
}>`
  transform: translateX(${(props) => props.percentage});
  transition: transform 0.75s cubic-bezier(0.5, 0.2, 0.3, 1.1);

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
`;

export const ValueProps: React.FC = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const slideScrollHeight = 750;
  const slideCount = dummyData.length;

  useEffect(() => {
    const wrapper = containerRef.current;

    const updateSlides = () => {
      if (wrapper) {
        const wrapperRect = wrapper.getBoundingClientRect();
        const wrapperTop = wrapperRect.top;

        const progress = Math.floor(-wrapperTop / slideScrollHeight);

        const updatedScrolljackedIndex = Math.max(
          0,
          Math.min(slideCount - 1, progress)
        );

        setActiveSlideIndex(updatedScrolljackedIndex);
      }
    };
    window.addEventListener('scroll', updateSlides);
    updateSlides();

    return () => {
      window.removeEventListener('scroll', updateSlides);
    };
  }, [slideCount]);

  const handleClick = (index: number) => {
    const wrapper = containerRef.current;
    if (wrapper) {
      const wrapperRect = wrapper.getBoundingClientRect();
      const wrapperTop = wrapperRect.top;
      const scrollPosition = window.scrollY;
      const wrapperTopPage = wrapperTop + scrollPosition + 1;

      const scrollTo = wrapperTopPage + slideScrollHeight * index;

      window.scrollTo(0, scrollTo);
    }
  };

  const containerHeight = slideScrollHeight * (slideCount + 1);

  return (
    <Box
      overflow="clip"
      height={containerHeight}
      ref={containerRef}
      fontFamily="accent"
    >
      <ContentContainer>
        <Box position="sticky" top={{ _: -104, sm: 64 }} py={64}>
          <FlexBox flexDirection="column" pb={16}>
            <Text fontSize={18} pb={4}>
              Active learning
            </Text>
            <Text fontSize={34} fontWeight="normal" as="h2" pb={4}>
              Everything you need to advance
            </Text>
            <FlexBox
              as="ul"
              listStyle="none"
              m={0}
              p={0}
              height="fit-content"
              ml={-8 as 0}
              justifyContent={{ _: 'center', sm: 'initial' }}
            >
              {dummyData.map(({ title }, i) => {
                const isActiveSlide = i === activeSlideIndex;
                return (
                  <Box as="li" key={`${title}-slide-indicator`}>
                    <TextButton
                      p={8}
                      onClick={() => handleClick(i)}
                      aria-current={isActiveSlide}
                      aria-disabled={isActiveSlide}
                      aria-label={`go to slide ${i + 1}`}
                    >
                      <SlideIndicator
                        width={{ _: 60, sm: 80 }}
                        height={4}
                        isActiveSlide={isActiveSlide}
                      />
                    </TextButton>
                  </Box>
                );
              })}
            </FlexBox>
            <FlexBox />
          </FlexBox>
          <LineGridPatternContainer
            width="100%"
            height={{ _: 680, sm: 612, md: 544 }}
          >
            <FlexBox
              flexDirection="row"
              fontFamily="accent"
              width={`${slideCount * 100}%`}
              aria-live="polite"
            >
              {dummyData.map(({ title, bullets }, i) => (
                <Slide
                  flexDirection={{ _: 'column-reverse', md: 'row' }}
                  flexShrink={0}
                  key={`${title}-slide`}
                  percentage={`${-activeSlideIndex * 100}%`}
                  width={`${100 / slideCount}%`}
                  height={{ _: 612, md: 544 }}
                  pt={{ _: 24, lg: i % 2 ? (144 as 0) : 24 }}
                  pl={24}
                  pr={{ _: 24, md: 156 as 0 }}
                  aria-hidden={i !== activeSlideIndex}
                >
                  <Box
                    width={{ _: '100%', md: '40%' }}
                    height={{ _: '50%', md: '100%' }}
                    pt={{ _: 24, md: 64 }}
                    pr={32}
                  >
                    <Text fontSize={{ _: 22, md: 26 }} pb={24}>
                      {title}
                    </Text>
                    {bullets.length > 0 && (
                      <FlexBox flexDirection="column" gap={24}>
                        {bullets.map((bulletText) => (
                          <FlexBox
                            flexDirection="row"
                            alignItems="flex-start"
                            key={bulletText}
                          >
                            <Image
                              src={asteriskBulletPoint}
                              alt=""
                              aria-hidden
                            />{' '}
                            <Text fontSize={{ _: 16, md: 18 }} pl={16}>
                              {bulletText}
                            </Text>
                          </FlexBox>
                        ))}
                      </FlexBox>
                    )}
                  </Box>
                  <FlexBox
                    bg="white-400"
                    border={1}
                    borderColor="border-tertiary"
                    borderRadius="lg"
                    width={{ _: '100%', md: 500 }}
                    height={{ _: '75%', md: 375 }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text>IMAGE PLACEHOLDER</Text>
                  </FlexBox>
                </Slide>
              ))}
            </FlexBox>
          </LineGridPatternContainer>
        </Box>
      </ContentContainer>
    </Box>
  );
};

const dummyData = [
  {
    title: 'Upgrade your skills',
    bullets: [
      'Get guided paths and interactive lessons for all skill levels',
      'Learn in-demand topics like AI, data, cloud, cybersecurity, and more',
    ],
    image: {
      src: '',
      alt: '',
    },
  },
  {
    title: 'Hands-on learning',
    bullets: [
      'Prepare for top industry certs from AWS, Microsoft, CompTIA, and more',
      "Build projects that simulate what you'll experience in the real world",
    ],
    image: {
      src: '',
      alt: '',
    },
  },
  {
    title: 'Transform your team',
    bullets: [
      'Build skills to meet changing business needs',
      'Equip your team with the tools and features that get results',
    ],
    image: {
      src: '',
      alt: '',
    },
  },
  {
    title: 'Build AI skills',
    bullets: [
      'AI courses and case studies',
      'AI-powered features for efficient learning',
    ],
    image: {
      src: '',
      alt: '',
    },
  },
];
