import { Box, FillButton, FlexBox, Text } from '@codecademy/gamut';
import { DiagonalADense, DotDense } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { getRegisterPath } from '@mono/util-url';
import Head from 'next/head';
import { useEffect, useState } from 'react';

import { GradientPattern } from '../GradientPattern';

const words = ['self', 'potential', 'career', 'future', 'growth'];
const colors = ['#ff8c00', '#FFD301', '#AEE938', '#67C4FF', '#E91C12'];

const Image = Box.withComponent('img');

const BottomBorderSvgBox = styled(FlexBox)<{ svgColor: string }>`
  color: ${(props) => props.svgColor};
`;

const StyledImageContainer = styled(Box)<{ svgColor: string }>`
  color: ${(props) => props.svgColor};
`;

const AnimatedText = styled(Text)<{ textColor: string; width: string }>`
  animation: typing 1s steps(8) forwards,
    blink 1s steps(2, jump-none) infinite alternate;
  animation-delay: 0s, 1s; // delays the blink to happen after typing is done
  will-change: width;

  width: ${(props) => props.width};
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid ${(props) => props.textColor};
  color: ${(props) => props.textColor};

  @keyframes typing {
    from {
      width: 0;
    }
  }
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  @media (prefers-reduced-motion: reduce) {
    animation: none;
    border-right: none;
  }
`;

const getImageUrl = (word: string, device: 'mobile' | 'desktop') =>
  `https://static-assets.codecademy.com/assets/homepage/hero/v1/${word}-${device}.webp`;

const flattenHeroImgSrcSet = (words: string[]): string => {
  // Create array of all image URLs (both mobile and desktop versions)
  const allImageUrls = words.flatMap((word) => [
    getImageUrl(word, 'mobile'),
    getImageUrl(word, 'desktop'),
  ]);
  return allImageUrls.join(', ');
};

export const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [key, setKey] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  // Change word every 6 seconds, 6 seconds is minimum for a11y
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
      setKey((prev) => prev + 1);
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Head>
        <link
          rel="preload"
          as="image"
          imageSrcSet={flattenHeroImgSrcSet(words)}
        />
      </Head>
      <Box
        pt={{ _: 0, md: 48, lg: 40 }}
        px={{ _: 0, sm: 32, lg: 64, xl: 96 }}
        maxWidth={1440}
        mx="auto"
      >
        <FlexBox
          flexDirection={{ _: 'column-reverse', md: 'row' }}
          position="relative"
        >
          <FlexBox
            flexDirection="column"
            zIndex={2}
            mt={{ _: -48, md: 50, lg: 80 } as {}} // position text relative to hero image
          >
            <Box
              position={{ _: 'relative', sm: 'absolute', md: 'relative' }}
              bottom={{ _: 0, sm: 126, md: 0 }}
            >
              <Text
                variant="title-xxl"
                fontWeight={400}
                fontSize={{ _: 40, xs: 64, sm: 80, lg: 116 } as {}} // custom font sizes
                as="h1"
                mb={{ _: 24, md: 48 }}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                aria-live={isFocused ? 'polite' : 'off'}
              >
                <Box
                  background={theme.colors['background-primary']}
                  p={16}
                  pl={{ _: 16, md: 0 }}
                  width="fit-content"
                  whiteSpace="nowrap"
                  lineHeight="normal"
                >
                  Develop your
                </Box>
                <FlexBox
                  background={theme.colors.black}
                  width="fit-content"
                  position="relative"
                  py={16}
                  pl={{ _: 8, sm: 16 }}
                  pr={{ _: 16, lg: 32 }}
                  flexDirection="column"
                >
                  <AnimatedText
                    key={key}
                    textColor={colors[currentIndex]}
                    width={`${words[currentIndex].length + 1.5}ch`} // +1 for the slash, +0.5 for the cursor
                    fontFamily="accent"
                    fontSize={
                      {
                        _: 40,
                        xs: 56,
                        sm: 70,
                        lg: 100,
                      } as {}
                    } // more custom font sizes
                  >
                    {`/${words[currentIndex]}`}
                  </AnimatedText>
                  <BottomBorderSvgBox
                    position="absolute"
                    bottom={0}
                    right={0}
                    svgColor={colors[currentIndex]}
                    width="100%"
                  >
                    <DotDense height={8} aria-hidden />
                  </BottomBorderSvgBox>
                </FlexBox>
              </Text>
            </Box>
            <FlexBox
              flexDirection={{ _: 'column', sm: 'row', md: 'column' }}
              maxWidth={{ _: 'none', lg: 380 }}
              mx={{ _: 16 }}
              mb={{ _: 32 }}
              mt={{ _: 0, sm: 96, md: 0 }}
              justifyContent={{
                _: 'flex-start',
                sm: 'space-between',
                md: 'flex-start',
              }}
            >
              <Text
                fontSize={{ _: 18, md: 22, lg: 26 }}
                fontWeight={400}
                mb={{ _: 16, md: 24 }}
                maxWidth={{ _: 'none', md: 304, lg: 'none' }}
                as="p"
              >
                Learn tech skills, grow your career, unlock new opportunities
              </Text>
              <FillButton
                href={getRegisterPath()}
                width={{ _: '100%', sm: 250 }}
              >
                Sign up
              </FillButton>
            </FlexBox>
          </FlexBox>
          <StyledImageContainer svgColor={colors[currentIndex]}>
            <Box
              width={{ _: '100%', md: '60%' }}
              maxWidth={{ _: 'none', xl: 866 }}
              height={{ _: 'auto' }}
              position={{ _: 'relative', md: 'absolute' }}
              right={{ _: undefined, md: 32, lg: 0 }}
            >
              <DotDense
                height={32}
                position="absolute"
                zIndex={2}
                width={{ _: '100%', md: 'calc(100% + 32px)' }}
                right={{ _: 0, md: 0, lg: -2, xl: 0 }}
                aria-hidden
              />
              <Image
                src={getImageUrl(words[currentIndex], 'mobile')}
                width="100%"
                height="100%"
                zIndex={1}
                position="relative"
                aria-hidden
                display={{ _: 'block', md: 'none' }}
              />
              <Image
                src={getImageUrl(words[currentIndex], 'desktop')}
                width="100%"
                height="100%"
                zIndex={1}
                position="relative"
                aria-hidden
                display={{ _: 'none', md: 'block' }}
              />
              <Box // sm and above have a gradient applied
                height={{ _: '100%', md: '50%' }}
                width="100%"
                zIndex={0}
                position="absolute"
                bottom={-16}
                right={16}
                aria-hidden
                display={{ _: 'none', sm: 'block' }}
              >
                <GradientPattern
                  Pattern={DiagonalADense}
                  width="100%"
                  height="100%"
                  viewBox="0 0 707 288"
                  preserveAspectRatio="none"
                  patternHeight="0.222222"
                  patternWidth="0.090523"
                  transform="scale(0.00141443 0.00347222)"
                  firstColor={theme.colors['background-primary']}
                  secondColor={colors[currentIndex]}
                  gradientCoords={{
                    x1: '353.5',
                    x2: '353.5',
                    y1: '0',
                    y2: '288',
                  }}
                />
              </Box>
              <DiagonalADense
                height={17}
                width="100%"
                zIndex={0}
                position="relative"
                bottom={0}
                right={0}
                aria-hidden
                display={{ _: 'block', sm: 'none' }}
              />
            </Box>
          </StyledImageContainer>
        </FlexBox>
      </Box>
    </>
  );
};
