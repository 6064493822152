import { Column, ContentContainer, LayoutGrid } from '@codecademy/gamut';
import { GridRegular } from '@codecademy/gamut-patterns';
import { theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

const StyledPattern = styled(GridRegular)`
  display: block;
  height: 2.5rem;
  pointer-events: none;
  color: ${theme.colors['blue-300']};
`;

export const GridRegularDivider: React.FC<
  ComponentProps<typeof ContentContainer>
> = ({ ...props }) => (
  <ContentContainer {...props}>
    <LayoutGrid columnGap={24}>
      <Column offset={1} size={10}>
        <StyledPattern />
      </Column>
    </LayoutGrid>
  </ContentContainer>
);
