import { PatternProps } from '@codecademy/gamut-patterns';
import React, { RefAttributes, useId } from 'react';

type GradientPatternProps = {
  // pattern component from Gamut Patterns
  Pattern: React.ForwardRefExoticComponent<
    PatternProps & RefAttributes<SVGSVGElement>
  >;

  // SVG attributes
  width: string;
  height: string;
  viewBox: string;
  preserveAspectRatio?: string;

  // pattern attributes
  patternWidth: string;
  patternHeight: string;
  transform: string;
  patternContentUnits?: string;

  // linear gradient attributes
  firstColor: string;
  secondColor: string;
  gradientCoords: {
    x1: string;
    x2: string;
    y1: string;
    y2: string;
  };

  // clip path attributes
  rx?: string;
};

export const GradientPattern: React.FC<GradientPatternProps> = ({
  Pattern,
  width,
  height,
  viewBox,
  preserveAspectRatio = 'none',
  patternHeight,
  patternWidth,
  transform,
  patternContentUnits = 'objectBoundingBox',
  firstColor,
  secondColor,
  gradientCoords,
  rx,
}) => {
  const uid = useId();
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      preserveAspectRatio={preserveAspectRatio}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden
    >
      <mask
        id={`mask${uid}`}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width={width}
        height={height}
      >
        <rect width={width} height={height} fill={`url(#pattern${uid})`} />
      </mask>

      <g mask={`url(#mask${uid})`}>
        <rect
          width={width}
          height={height}
          rx={rx}
          fill={`url(#paint${uid}_linear)`}
        />
      </g>

      <defs>
        <pattern
          id={`pattern${uid}`}
          patternContentUnits={patternContentUnits}
          width={patternWidth}
          height={patternHeight}
        >
          <use xlinkHref={`#image${uid}`} transform={transform} />
        </pattern>

        <linearGradient
          id={`paint${uid}_linear`}
          x1={gradientCoords.x1}
          y1={gradientCoords.y1}
          x2={gradientCoords.x2}
          y2={gradientCoords.y2}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={firstColor} />
          <stop offset="1" stopColor={secondColor} />
        </linearGradient>

        {rx && (
          <clipPath id={`clip${uid}`}>
            <rect width={width} height={height} rx={rx} />
          </clipPath>
        )}

        <svg id={`image${uid}`}>
          <Pattern />
        </svg>
      </defs>
    </svg>
  );
};
